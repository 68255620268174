import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {DataService} from '../../../core/http/data.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../../core/loading/loading.service';

@Injectable()
export class UsersService extends DataService {
  endPointUrl = 'users';
  endPointUrlSendLoginDetails = 'password/email';
  constructor(http: HttpClient,
              private loading: LoadingService) {
    super(http);
  }

  loadUsers(orderby = '', az = '', search = '', page = '', paginate = '', active = 1): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrl}?type=1&orderby=${orderby}&az=${az}&search=${search}&page=${page}&paginate=${paginate}&active=${active}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  loadPageUsers(page, paginate): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`${this.endPointUrl}?type=1&page=${page}&paginate=${paginate}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  sendLoginDetails(postData): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Create(postData, this.endPointUrlSendLoginDetails)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  selectCompanyOfUser(postData, userid): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Update(postData, `${this.endPointUrl}/${userid}`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getUserToken(userid): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.Get(userid, `tokens`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

  getUserInfoFromAuth(): Observable<any> {
    this.loading.setLoadingBarStatus(true);
    return this.GetAll(`auth`)
      .pipe(
        tap((response: any) => {
          this.loading.setLoadingBarStatus(false);
          return response;
        }),
        catchError((val: any) => {
          this.loading.setLoadingBarStatus(false);
          return throwError(val);
        })
      );
  }

}
